import React from "react"
import ListItem from "./listItem"

const news = [
  {
    name: "Aaj Tak",
    subs: "18.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l786U3e54C3BDoTnJ3Hi6sFbg3ICTMIBuiPZ5g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCt4t-jeY85JegMlZ-E5UWtA",
    rank: 1,
  },
  {
    name: "ABP NEWS",
    subs: "14.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78ZFIEfy0js5x4XuVKa4HZVnBKrOgS_PrRkcw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCRWFSbif-RFENbBrSiez1DA",
    rank: 2,
  },
  {
    name: "IndiaTV",
    subs: "14.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78d75rcqY47dDCDsXwt79sSf7R2FgOzHtOWiw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCttspZesZIDEwwpVIgoZtWQ",
    rank: 3,
  },
  {
    name: "Sebastián Yatra",
    subs: "10.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-kGqoy57sh8qNcKT166J7Kw2E0ozojXsiXfQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCssFxr-IdIa-wpG3jUXYrmw",
    rank: 4,
  },
  {
    name: "ABP NEWS HINDI",
    subs: "9.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79fFB-4jY3xLDHVvEQ1L1EV_f5dIVgElw0g_A=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCmphdqZNmqL72WJ2uyiNw5w",
    rank: 5,
  },
  {
    name: "Zee News",
    subs: "8.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79-OwUcmlPv4HeEUOP4TAPWUVyW7R38YW1fqQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCIvaYmXn910QMdemBG3v1pQ",
    rank: 6,
  },
  {
    name: "NMF News",
    subs: "7.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78RpaF6RoX5DzH1aEzOtAbXj9aF6jgq6EylFA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/narendramodionline",
    rank: 7,
  },
  {
    name: "The Lallantop",
    subs: "7.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_lvTcVogaNamnVMfkvDZ8aaj7boIlvTIy-_w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCx8Z14PpntdaxCt2hakbQLQ",
    rank: 8,
  },
  {
    name: "Indosiar",
    subs: "7.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78zaeLTrFQ5-LACxuEDAtEwGLRUYdMH75k6xw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCYqOeAXJm8yV9sJ8Ud3cR7A",
    rank: 9,
  },
  {
    name: "CNN",
    subs: "7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79d7bv3lD8Hf5Cu3jBLyekhBnG9tDQt3we-LA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCupvZG-5ko_eiXAupbDfxWw",
    rank: 10,
  },
  {
    name: "Barcroft TV",
    subs: "6.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_3-Jfq7GBPAArgi_FNs4UbmJalMT-Oz4-EBA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCfwx98Wty7LhdlkxL5PZyLA",
    rank: 11,
  },
  {
    name: "ABS-CBN News",
    subs: "6.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-M7-Hp4ZgjI2tP0jePyiM2FmjPxR6GAhMy=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCE2606prvXQc_noEqKxVJXA",
    rank: 12,
  },
  {
    name: "Philip DeFranco",
    subs: "6.4M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-EwD6j7bwgcGeMJLuuBgzsqWqV0rhSTXcH7w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UClFSU9_bUb4Rc6OYfTt5SPw",
    rank: 13,
  },
  {
    name: "Inside Edition",
    subs: "6.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78ul_bJgxDQmLnCNbaHTnve8aZ1qfBGmmY6=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC9k-yiEpRHMNVOnOi_aQK8w",
    rank: 14,
  },
  {
    name: "ABC News",
    subs: "6.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-wuYtDksmLBwlT5PE9LdMlKt0X2762ynpXLg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCBi2mrWuNuyYy4gbM6fU18Q",
    rank: 15,
  },
  {
    name: "Thairath",
    subs: "6.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l799zLDwvvYepIPzFfE4apatcjuWsLXJRL_q3g=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCrFDdD-EE05N7gjwZho2wqw",
    rank: 16,
  },
  {
    name: "Daftar Populer",
    subs: "6.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79FzzVUE5u4ArnCZf3rTo4wKyy4lWLlyKlHKw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCQW2tTNJ40V2T6MqV1eMZ0w",
    rank: 17,
  },
  {
    name: "Vox",
    subs: "6.1M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79Xm2sLXY1MppHw9OkfY9LaOru1P_KSReIWhg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCLXo7UDZvByw2ixzpQCufnA",
    rank: 18,
  },
  {
    name: "GMA Public Affairs ",
    subs: "5.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7861EAO2tW1z7P5d0fcueYrtItXh7jWWhtslQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCj5RwDivLksanrNvkW0FB4w",
    rank: 19,
  },
  {
    name: "AlHayah TV Network",
    subs: "5.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-spUq2kIa04t4Zsn7pVrLXepKvRj_M8pg8Lg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCzMoibQRslh_1bTuW0YXc6A",
    rank: 20,
  },
  {
    name: "AMARIN TVHD",
    subs: "5.6M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-7Zp_-RW66GvJCDVUyWC47Q5_uy_nve4izsw=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCzMoibQRslh_1bTuW0YXc6A",
    rank: 21,
  },
  {
    name: "News Tak",
    subs: "5.5M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-YEezafAS9nC0r7rIlxzZfaQ0xmntLRSD0fg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCAUNFgpgVisKPL3yq_-Nj-Q",
    rank: 22,
  },
  {
    name: "قناة المجلة",
    subs: "5.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78ngVWX2afrfaLzP6-h57ejV1QW3aPRSV2VfA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC6E13U6cZmwpfGVwBgC4isQ",
    rank: 23,
  },
  {
    name: "DramaAlert",
    subs: "5.2M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_D8Ac9EycltqJhDCTeTZ5xHayDLcNO2g167w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/NewDramaAlert",
    rank: 24,
  },
  {
    name: "เรื่องเล่าเช้านี้ บีอีซี-เทโร",
    subs: "4.9M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-w5B4QQHUmQ7HlDSxqM_k3ZTkVrGrpHMQW7w=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/MorningNewsTV3",
    rank: 25,
  },
  {
    name: "NDTV",
    subs: "4.8M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79oruJX0LPGeveh9zImzTTHOBpTJgleh4o4HA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCZFMm1mMw0F81Z37aaEzTUA",
    rank: 26,
  },
  {
    name: "BBC News ",
    subs: "4.7M",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-ROxk4wco8xCKXtbSltQpYTsAvqNkrkQv1nA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC16niRr50-MSBwiO3YDb3RA",
    rank: 27,
  },
]

const News = () => <ListItem data={news} />

export default News
